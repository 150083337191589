.App {
  text-align: center;
  background-image: url('./background.jpg');
  background-size: 100vh;
  height: 100vh;
  width: 100vw;
}

.swal2-title {
  color: black !important;
}

.game-code-value, .game-name-value {
  color: black;
}

#copyButton:active+.game-code-value {
  color: #6d6d71;
  transition: color 100ms ease-out;
}

#copyButton {
  padding: 0;
}

.swal2-actions {
  margin-top: 0 !important;
}

.roulette-main-container {
  background-color: white;
  border-radius: 12px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.roulette-pro-regular-design-top {
  background: #007bff !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  width: 32px !important;
  bottom: 50px !important;
  height: 20px !important;
  top: -70% !important;
  border-top-left-radius: 10% !important;
  border-top-right-radius: 10% !important;
}

.roulette-pro-regular-prize-item-wrapper {
  border: 2px solid white !important;
  background: white !important;
}

.roulette-pro-regular-prize-item-image {
  max-height: none !important;
}

.roulette-pro-regular-prize-item-text {
  margin-top: 12px !important;
  padding-top: 8px !important;
  border-top: 2px solid #6d6d71  !important;
  background: none !important;
  color: black!important;
}

.roulette-pro-regular-design-top::after{
  content: '';            
  position: absolute; 
  bottom: -20px;             
  left: 50%; 
  margin-left: -16px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 20px solid #007bff;
}

.roulette-header-wrapper {
  background-color: white;
  padding: 50px;
}

.start-btn {
  background-color: #007bff;
  color: #ffffff;
  width: 90%;
  border: 0;
  padding: 10px;
  font-weight: 600;
  border-radius: 12px;
}
